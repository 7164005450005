import { useEffect, useState } from "react";
import { useRouter } from "next/router";

const useMetaNoIndex = () => {
  const [noIndex, setNoIndex] = useState(false);
  const router = useRouter();
  const arry = router.pathname.split("/");
  const lastElement = arry[arry.length - 1];
  const isDev: boolean = process.env.NEXT_PUBLIC_ADDITIONAL_META_ROBOTS === "true";
  const isGlobalSearch: boolean = router.asPath.split("/")[1] === 'search';
  const isInterestForm = lastElement === 'interest-form';

  useEffect(() => {
    if (isDev || isGlobalSearch || isInterestForm) {
      setNoIndex(true);
    } else {
      setNoIndex(false);
    }
  }, [router.asPath]);

  return {
    noIndex
  };
};

export default useMetaNoIndex;