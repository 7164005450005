import { combineReducers } from "redux-immer";
import produce from "immer";
import { store } from "./store";

import counter from "@redux/slices/counter";
import passTicketId from "@redux/slices/passTicketId";
import verifyDialog from "@redux/slices/verifyDialog";
import queryMRTG from "@redux/slices/queryMRTG";
import search from "@redux/slices/search";
import snackbar from "@redux/slices/snackbar";
import popupAlert from "@redux/slices/popupAlert";

import warning from "./slices/warning";
import loading from "./slices/rootLoading";
import snackbars from "./slices/rootSnackbar";
import PopupForm from "./slices/PopupForm";
import list from "./slices/list";
import addTicketData from "./slices/addTicketData";
import npsInfo from "./slices/npsInfo";

const baseReducer = {
  counter,
  search,
  snackbar,
  popupAlert,
  passTicketId,
  list,
  [addTicketData.name]: addTicketData.reducer,
  [verifyDialog.name]: verifyDialog.reducer,
  [npsInfo.name]: npsInfo.reducer,
  [queryMRTG.name]: queryMRTG.reducer,
  [PopupForm.name]: PopupForm.reducer,
  [warning.name]: warning.reducer,
  [loading.name]: loading.reducer,
  [snackbars.name]: snackbars.reducer,
};

const rootReducer = combineReducers(produce, { ...baseReducer });

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
