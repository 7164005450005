import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TNPSInfo } from "./types";

const initialState: TNPSInfo = {
  open: false,
  totalInfo: 0,
  titleNpsInfo : "",
  descriptionNpsInfo : ""
};

const npsInfoSlice = createSlice({
  name: "npsInfo",
  initialState,
  reducers: {
    setNpsInfo: (state, { payload }: PayloadAction<TNPSInfo>) => {
      Object.assign(state, payload);
      state.open = true;
    },
    setCloseNpsInfo: (state) => {
      state.open = false;
    },
    setClearNpsInfo: () => initialState,
  },
});

export const { setNpsInfo, setCloseNpsInfo, setClearNpsInfo } =
  npsInfoSlice.actions;

export const selectNpsInfo = (state: RootState) => state.rootReducer.npsInfo;

export default npsInfoSlice;
