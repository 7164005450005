import { createTheme } from "@mui/material/styles";
import color from "./color";

export interface ITheme {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
}

const customTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          body: {
            backgroundColor: "#EDF0F2",
            color: color.general.main,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '@media (max-width: 1024px)': {
            width: 20,
            height: 20
          },
          width: 24,
          height: 24
        }
      }
    }
  },
  palette: {
    ...color,
  },
  typography: {
    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: 14,
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: '40px !important',
      fontWeight: 'bold',
      lineHeight: '54px',
    },
    h2: {
      fontSize: '32px !important',
      fontWeight: 'bold',
      lineHeight: '44px',
    },
    h3: {
      fontSize: '24px !important',
      fontWeight: 'bold',
      lineHeight: '34px',
    },
    subtitle1: {
      fontSize: '20px !important',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: color.primary.main,
    },
    subtitle2: {
      fontSize: '16px',
      color: color.inactive.secondary,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '22px',
    }
  },
});

export default customTheme;
