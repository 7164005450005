import CryptoJS from "crypto-js";
import { setAuthData, setLoginStorageData, setOTPData, setToken } from "./storage";

export function encryptStorage(param: any, flag: number) {
  const newParam = JSON.stringify(param);
  const encrypt = CryptoJS.AES.encrypt(
    newParam,
    process.env.NEXT_PUBLIC_PROJECT_ENCRYPT as string,
  ) as unknown as string;
  switch (flag) {
    case 0:
      setToken(String(encrypt));
      break;
    case 1:
      setLoginStorageData(String(encrypt));
      break;
    case 2:
      setAuthData(String(encrypt));
      break;
    case 3:
      setOTPData(String(encrypt));
      break;
  }
}

export function decrypTokenStorage(param: string) {
  const decrypt = CryptoJS.AES.decrypt(param, process.env.NEXT_PUBLIC_PROJECT_ENCRYPT as string);
  return decrypt.toString(CryptoJS.enc.Utf8);
}
