import { parseCookies, destroyCookie } from "nookies";

import {
  // COOKIE_CONFIG,
  ACCESS_TOKEN_COOKIE,
  REFRESH_TOKEN_COOKIE,
  USER_ID_COOKIE,
  LOCALE_COOKIE,
} from "@libraries/types/cookies";

/**
 * Adds `cookie` function on `res.cookie` to set cookies for response
 */

export const getAccessTokenCookie = () => {
  const cookies = parseCookies();
  return cookies[ACCESS_TOKEN_COOKIE];
};

export const getUserIdCookie = () => {
  const cookies = parseCookies();
  return cookies[USER_ID_COOKIE];
};

export const getRefreshTokenCookie = () => {
  const cookies = parseCookies();
  return cookies[REFRESH_TOKEN_COOKIE];
};

export const getLocaleCookie = () => {
  const cookies = parseCookies();
  return cookies[LOCALE_COOKIE] || "id";
};

export const clearCookies = () => {
  destroyCookie({}, ACCESS_TOKEN_COOKIE, { path: "/", domain: ".mytens.co.id" });
  destroyCookie({}, REFRESH_TOKEN_COOKIE, { path: "/", domain: ".mytens.co.id" });
  destroyCookie({}, USER_ID_COOKIE, { path: "/", domain: ".mytens.co.id" });
};

export default getAccessTokenCookie;
