/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import NotificationServices from "@services/notification";
import { paramsList } from "@services/interface";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    getCountNotification: builder.query({
      query: NotificationServices.getCountNotification,
      providesTags: ["Notification"],
    }),
    getNotificationList: builder.query<any, { paramsList: paramsList }>({
      query: ({ paramsList }) => NotificationServices.getNotificationList(paramsList),
      providesTags: ["Notification"],
    }),
    putReadNotification: builder.mutation({
      query: NotificationServices.putReadNotificatin,
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const {
  useGetCountNotificationQuery,
  useGetNotificationListQuery,
  usePutReadNotificationMutation,
} = notificationApi;
