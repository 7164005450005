import callAPI from "@helpers/network";
import ProfileServices from "@services/profile";
import { IProfile } from "@containers/Profile/interface";

export const PROFILE_API = {
  getProfile: async () => {
    return callAPI(ProfileServices.getProfile());
  },
  putProfile: async (data: Partial<IProfile>) => {
    return callAPI(ProfileServices.putProfile(data));
  },
  getIndustry: async () => {
    return callAPI(ProfileServices.getIndustry());
  },
  getCountry: async () => {
    return callAPI(ProfileServices.getCountry());
  },
  getCity: async (countryName: string) => {
    return callAPI(ProfileServices.getCity(countryName));
  },
  PUT_CUSTOMER_CHANGE_PASSWORD: async (
    data: {
      newPassword?: string;
      reEnterNewPassword?: string;
      otp: string;
      signatureCode: string;
    },
    language: string,
  ) => {
    return callAPI(ProfileServices.PutCustomerChangePassword(data, language));
  },
};
