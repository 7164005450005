import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TVerifyDialog } from "./types";

const initialState: TVerifyDialog = {
  open: false,
  title: "",
  itemList: [],
  informationList: [],
  hasApplyButton: false,
  applyButton: [],
  type: "",
  image: "",
  messages: [],
  description: "",
  listItem: [],
  dataSegmentContact: [],
};

const verifyDialogSlice = createSlice({
  name: "verifyDialog",
  initialState,
  reducers: {
    setVerifyDialog: (state, { payload }: PayloadAction<TVerifyDialog>) => {
      Object.assign(state, payload);
      state.open = true;
    },
    setCloseVerifyDialog: (state) => {
      state.open = false;
    },
    setClearVerifyDialog: () => initialState,
  },
});

export const { setVerifyDialog, setCloseVerifyDialog, setClearVerifyDialog } =
  verifyDialogSlice.actions;

export const selectVerifyDialog = (state: RootState) => state.rootReducer.verifyDialog;

export default verifyDialogSlice;
