import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";

export interface IPopupAlert {
  message?: string;
  onClose?: null | (() => void);
  variant?: "success" | "failed" | "loading";
  buttonLabel?: string;
}

export const initialState: IPopupAlert = {
  message: "",
  onClose: null,
  variant: "success",
  buttonLabel: "Okay",
};

const popupAlertSlice = createSlice({
  name: "popupAlert",
  initialState,
  reducers: {
    setSuccessAlert: (state, action: PayloadAction<IPopupAlert>) => {
      const { message, onClose, buttonLabel = "Okay" } = action.payload;
      state.message = message;
      state.variant = "success";
      state.onClose = onClose;
      state.buttonLabel = buttonLabel;
    },
    setFailedAlert: (state, action: PayloadAction<IPopupAlert>) => {
      const { message, onClose, buttonLabel = "Okay" } = action.payload;
      state.message = message;
      state.variant = "failed";
      state.onClose = onClose;
      state.buttonLabel = buttonLabel;
    },
    setLoadingAlert: (state, action: PayloadAction<IPopupAlert>) => {
      const { onClose, buttonLabel = "Okay" } = action.payload;
      state.message = "Please Wait...";
      state.variant = "loading";
      state.onClose = onClose;
      state.buttonLabel = buttonLabel;
    },
    setCloseAlert: (state) => {
      state.message = "";
    },
  },
});

export const {
  setLoadingAlert,
  setSuccessAlert,
  setFailedAlert,
  setCloseAlert
} = popupAlertSlice.actions;

export const selectPopupAlert = (state: RootState) => state.rootReducer.popupAlert;

export default popupAlertSlice.reducer;
