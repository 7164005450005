import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootSnackbar } from "./type";

const initialState: TRootSnackbar = {
  open: false,
  message: "",
  variant: "",
  duration: 4000,
  anchor: "top-center",
};

const rootSnackbarSlice = createSlice({
  name: "rootSnackbar",
  initialState,
  reducers: {
    setOpenSnackbar: (state, { payload }: PayloadAction<TRootSnackbar>) => {
      Object.assign(state, payload);
      state.open = true;
      state.duration = payload.duration;
      state.anchor = payload.anchor;
    },
    setCloseSnackbar: (state) => {
      state.open = false;
    },
    setClearSnackbar: () => initialState,
  },
});

export const { setOpenSnackbar, setCloseSnackbar, setClearSnackbar } = rootSnackbarSlice.actions;
export const selectSnackbar = (state: RootState) => state.rootReducer.rootSnackbar;

export default rootSnackbarSlice;
