/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import InsightServices from "@services/insight";
import { MediaInfo } from "@libraries/services/interface";
import { AxiosResponse } from "axios";

export interface IProductSuggest {
  productName: string;
  story: string;
  productPath: string;
}
export interface InsightData {
  id: string;
  title: string;
  imageUrl: MediaInfo;
  story: string;
  date: string;
  category: string;
  categoryId: string;
  keyword?: string[];
  productSuggestion: IProductSuggest[];
  slug: string;
  canonical: string;
}

export const insightApi = createApi({
  reducerPath: "insightApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCategories: builder.query<
      AxiosResponse<
        {
          category: string;
          categoryId: string;
        }[]
      >,
      // eslint-disable-next-line @typescript-eslint/ban-types
      {}
    >({
      query: InsightServices.getCategories,
    }),
    getInsight: builder.query<
      AxiosResponse<InsightData[]> & { meta?: { page: number; totalPages: number } },
      { keyword?: string; category?: string; page?: number, lang?: string }
    >({
      query: ({ keyword, category, page, lang }) =>
        InsightServices.getInsight({ keyword, category, page }, lang),
    }),
    getInsightDetail: builder.query<AxiosResponse<InsightData>, { id: string; lang?: string }>({
      query: ({ id, lang }) => InsightServices.getInsightDetail(id, lang),
    }),
  }),
});

export const { useGetCategoriesQuery, useGetInsightDetailQuery, useGetInsightQuery } = insightApi;
