import { ILoginData, ITokenData } from "src/utils/storage-interface";
import { decrypTokenStorage } from "./storageService";

export function setRememberMe(data: string) {
  localStorage.setItem("REMEMBER_ME_STORAGE", data);
}

export function setAuthData(data: string) {
  localStorage.setItem("AUTHSTORAGE", data);
}

export function setToken(data: string) {
  localStorage.setItem("TOKENSTORAGE", data);
}

export function setLoginStorageData(data: string) {
  localStorage.setItem("LOGINSTORAGE", data);
}

export function setOTPData(data: string) {
  sessionStorage.setItem("OTPDATA", data);
}

export function setUserIdToGtm(data: string) {
  localStorage.setItem("userId", data);
}

export function getStorageRememberMe() {
  if (typeof window !== "undefined") {
    const getToken = window.localStorage.getItem("REMEMBER_ME_STORAGE");
    return getToken;
  }
}

export function resetAuth() {
  ["TOKENSTORAGE", "LOGINSTORAGE", "USERID"].forEach((item) => {
    localStorage.removeItem(item);
  });
  if (getStorageRememberMe() !== "true") {
    localStorage.removeItem("AUTHSTORAGE");
  }
}

export function getStorageAuthData() {
  if (typeof window !== "undefined") {
    const getToken = window.localStorage.getItem("AUTHSTORAGE");
    if (getToken) {
      const decrypt = decrypTokenStorage(String(getToken));
      return JSON.parse(decrypt);
    } else {
      return {};
    }
  }
}

export function getStorageToken() {
  const getToken = window.localStorage.getItem("TOKENSTORAGE");
  if (getToken) {
    const decrypt = decrypTokenStorage(String(getToken));
    return JSON.parse(decrypt) as ITokenData;
  } else {
    return {} as ITokenData;
  }
}

export function getStorageLogin() {
  if (typeof window !== "undefined") {
    const gotData = window.localStorage.getItem("LOGINSTORAGE");
    if (gotData) {
      const decrypt = decrypTokenStorage(String(gotData));
      return JSON.parse(decrypt) as ILoginData;
    } else {
      return {} as ILoginData;
    }
  }
}

export function getOTPData() {
  if (typeof window !== "undefined") {
    const gotData = window.sessionStorage.getItem("OTPDATA");
    if (gotData) {
      const decrypt = decrypTokenStorage(String(gotData));
      return JSON.parse(decrypt);
    } else {
      return {};
    }
  }
}
