import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import PrivilegeServices from "@services/privilege";

export const privilegeApi = createApi({
  reducerPath: "privilegeApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPrivilege: builder.query({
      query: PrivilegeServices.getPrivileges,
    }),
  }),
});

export const { useGetPrivilegeQuery } = privilegeApi;
