import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";

interface ITdsTicketId {
  tdsTicketId: string;
}

const initialState: ITdsTicketId = {
  tdsTicketId: "",
};

const ticketIdSlice = createSlice({
  name: "passTicketId",
  initialState,
  reducers: {
    setTdsTicketId: (state, action: PayloadAction<string>) => {
      state.tdsTicketId = action.payload;
    },
    clearTdsTicketId: (state) => {
      state.tdsTicketId = '';
    },
  },
});

export const { setTdsTicketId, clearTdsTicketId } = ticketIdSlice.actions;

export const selectTdsTicketId = (state: RootState) => state.rootReducer.passTicketId;

export default ticketIdSlice.reducer;
