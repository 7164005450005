import { AxiosRequestConfig } from "axios";
import { getStorageToken } from "@helpers/storage";
import { paramsList } from "@services/interface";

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const NotificationServices = {
  getCountNotification: (): AxiosRequestConfig => ({
    url: `${baseUrl}/notifications/notification/v3/count-customer`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  getNotificationList: (params: paramsList): AxiosRequestConfig => {
    const { language, ...rest } = params;
    return {
      url: `${baseUrl}/notifications/notification/v3/customer`,
      method: "GET",
      params: rest,
      headers: {
        Authorization: `Bearer ${getStorageToken().accessToken}`,
        lang: language ? language : "id",
      },
    };
  },
  putReadNotificatin: (notifId: string): AxiosRequestConfig => ({
    url: `${baseUrl}/notifications/notification/v3/read-customer/${notifId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
};

export default NotificationServices;
