import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TLoading = {
  name: string,
  value: boolean,
  values?: [],
}

const initialState: TLoading = {
  name: "SET_ROOT_LOADING",
  value: false,
  values: [],
};

const rootLoadingSlice = createSlice({
  name: "rootLoading",
  initialState,
  reducers: {
    setRootLoading: (state: any, { payload }: PayloadAction<TLoading>) => {
      const { name, value, values } = payload;
      if (payload.values) {
        values?.forEach((message: any) => {
          state[message.name] = message.value;
        });
      } else {
        state.name = name;
        state.value = value;
      }
    },
    setClearRootLoading: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const { setRootLoading, setClearRootLoading } = rootLoadingSlice.actions;

export const selectRootLoading = (state: RootState) => state.rootReducer.rootLoading;

export default rootLoadingSlice;
