import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAddTicketData } from "./types";

const initialState: TAddTicketData = {
  typeOfTicket: "",
  issueCategory: "",
  issueDescription: "",
  pic1Name: "",
  pic1Number: "",
  pic1Email: "",
  pic2Name: "",
  pic2Number: "",
  pic2Email: "",
};

const addTicketDataSlice = createSlice({
  name: "addTicketData",
  initialState,
  reducers: {
    setAddTicketData: (state, { payload }: PayloadAction<TAddTicketData>) => {
      Object.assign(state, payload);
    },
    // setCloseVerifyDialog: (state) => {
    //   state.open = false;
    // },
    setClearAddTicketData: () => initialState,
  },
});

export const {
  setAddTicketData,
  // setVerifyDialog,
  // setCloseVerifyDialog,
  setClearAddTicketData,
} = addTicketDataSlice.actions;

export const selectAddTicketData = (state: RootState) => state.rootReducer.addTicketData;

export default addTicketDataSlice;
