import { useRouter } from "next/router";
import React, { createContext, useEffect } from "react";

const LanguageDataContext = createContext({
  appLang: "",
});

const AppLangProvider = LanguageDataContext.Provider;

const LanguageProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const { pathname, query, asPath } = router;
    router.push({ pathname, query }, asPath, { locale: `${locale}` });
  }, [locale]);

  return (
    <AppLangProvider
      value={{
        appLang: `${locale}`,
      }}
    >
      {children}
    </AppLangProvider>
  );
};

export default LanguageProvider;
