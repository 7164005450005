import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { BASE_URL } from "@services/home";
import router from "next/router";
// import { IErrorResponse, IGenerateTokenResponse } from "src/utils/base-response";
import { IErrorResponse } from "src/utils/base-response";
import { encryptStorage } from "./storageService";
// import { getStorageLogin, getStorageToken } from "./storage";
// import { LOGIN_SERVICES } from "@repositories/login/login.hooks";
import { openSnackbar } from "@redux/actions";
import store from "@redux/store";
import getAccessTokenCookie from "@utils/cookie";
import { clearCookies } from "../utils/cookie";

function errorCase() {
  let errorMessage = "Something went wrong.";
  if (!navigator.onLine) {
    errorMessage = "No internet connection.";
  }
  store.dispatch(openSnackbar({ message: errorMessage || "", type: "error" }));
}

const reFetch = async (payload: AxiosRequestConfig, token?: string) => {
  if (token) {
    try {
      payload.timeout = 30000;

      const reRequest = await axios({
        ...payload,
        headers: {
          ...payload.headers,
          Authorization: `Bearer ${token}`,
        },
      });
      return reRequest.data;
    } catch (error: unknown) {
      const errorResp = error as IErrorResponse;
      if (
        router.asPath === "/" &&
        router.asPath.includes("/login") &&
        router.asPath.includes("/performance")
      ) {
        return errorResp.response.data;
      } else {
        return Promise.reject(errorResp.response.data);
      }
    }
  }
};

export async function callAPIGenerateToken(config: AxiosRequestConfig) {
  try {
    const response: AxiosResponse = await axios(config);
    return response.data;
  } catch (error: unknown) {
    const errorResp = error as IErrorResponse;
    const defaultError = {
      code: 500,
      status: "error",
      message: "Failed to fetch data. Please contact developer.",
    };
    if (!errorResp || !errorResp.response) {
      return Promise.reject(new Error(defaultError.message));
    } else if (typeof errorResp.response === "undefined") {
      return Promise.reject(new Error(defaultError.message));
    } else if (typeof errorResp.response?.data === "undefined") {
      return Promise.reject(new Error(defaultError.message));
    } else if (errorResp.response?.status >= 400) {
      errorCase();
    }
    return errorResp.response.data;
  }
}

export async function callAPI(payload: AxiosRequestConfig, isPublic = false) {
  // const isAuth = !!getAccessTokenCookie();
  payload.timeout = 30000;

  try {
    const authToken = (payload?.headers || {}).Authorization;
    const response: AxiosResponse = await axios({
      ...payload,
      headers: {
        ...payload?.headers,
        Authorization:
          authToken?.includes("Basic") || isPublic ? authToken : `Bearer ${getAccessTokenCookie()}`,
        lang: `${typeof window !== "undefined" ? router.locale : "id"}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    const errorResp = error as IErrorResponse;
    if (errorResp.response) {
      if (
        [
          "Token is expired",
          "Access token expired!",
          "Token is not valid!",
          "Invalid token!",
        ].includes(errorResp.response.data?.message)
        // || isAuth
      ) {
        await refresherToken();
        const newToken = getAccessTokenCookie();
        return await reFetch(payload, newToken);
      }
      return Promise.reject(errorResp.response.data);
    } else if (errorResp.response?.code >= 500) {
      errorCase();
    } else if (!errorResp || !errorResp.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}

// export const refresherToken = () =>
//   new Promise(async (resolve, reject) => {
//     try {
//       // const { data } = await LOGIN_SERVICES.POST_GENERATE_TOKEN();
//       // const generateAccessToken = data as IGenerateTokenResponse;
//       const options = await axios
//         .post(
//           // `${BASE_URL}/users-management/v3/auth/refreshtoken`,
//           `/api/refresh-token`,
//           // {
//           //   refreshToken: getRefreshTokenCookie(),
//           // },
//           // { headers: { Authorization: `Bearer ${generateAccessToken.accessToken}` } },
//         )
//         .then(function (response) {
//           const { data } = response;
//           return data;
//         })
//         .catch(function (error) {
//           return error;
//         });

//       const result = options;
//       console.log({ result });
//       const { accessToken, refreshToken, accessTokenExpiresIn, refreshTokenExpiresIn } = result;
//       encryptStorage(
//         {
//           accessToken: accessToken,
//           refreshToken: refreshToken,
//           accessTokenExpiresIn: accessTokenExpiresIn,
//           refreshTokenExpiresIn: refreshTokenExpiresIn,
//         },
//         0,
//       );
//       resolve(accessToken);
//     } catch (error) {
//       localStorage.clear();
//       router.push("/");
//       reject(error);
//     }
//   });

export const refresherToken = async () => {
  try {
    // const { data } = await LOGIN_SERVICES.POST_GENERATE_TOKEN();
    // const generateAccessToken = data as IGenerateTokenResponse;
    const options = await axios
      .post(
        // `${BASE_URL}/users-management/v3/auth/refreshtoken`,
        `/api/refresh-token`,
        // {
        //   refreshToken: getRefreshTokenCookie(),
        // },
        // { headers: { Authorization: `Bearer ${generateAccessToken.accessToken}` } },
      )
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch(function (error) {
        throw error;
      });

    const result = options;
    const { accessToken, refreshToken, accessTokenExpiresIn, refreshTokenExpiresIn } = result;
    encryptStorage(
      {
        accessToken: accessToken,
        refreshToken: refreshToken,
        accessTokenExpiresIn: accessTokenExpiresIn,
        refreshTokenExpiresIn: refreshTokenExpiresIn,
      },
      0,
    );
  } catch (error) {
    localStorage.clear();
    clearCookies();
    window.location.href = `/${router.locale === "en" ? "en/" : ""}login`;
  }
};

export default callAPI;
