import { IBillingAccount } from "@utils/storage-interface";

export const initialData = {
  name: "",
  email: "",
  dialCode: "",
  phoneNumber: "",
  businessName: "",
  industry: "",
  country: "",
  city: "",
  billingAccount: [] as IBillingAccount[],
};
