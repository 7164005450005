import { AxiosRequestConfig } from "axios";
import { paramsList } from "@services/interface";
import { getStorageToken } from "@helpers/storage";

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const MyProjectServices = {
  getProjectInfo: (lopId: string | string[] | undefined, language: string): AxiosRequestConfig => ({
    url: `${baseUrl}/services/customer/v1/dashboard/projects/${lopId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language,
    },
  }),
  getDeliverableList: (
    projectId: string,
    params: paramsList,
    language: string,
  ): AxiosRequestConfig => ({
    url: `${baseUrl}/services/customer/v1/dashboard/projects-deliverables/${projectId}`,
    method: "GET",
    params,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language,
    },
  }),
  getSCurve: (projectId: string | string[] | undefined, language: string): AxiosRequestConfig => ({
    url: `${baseUrl}/services/customer/v1/dashboard/projects-scurve/${projectId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language,
    },
  }),
};

export default MyProjectServices;
