import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TQueryMRTG } from "./types";

const initialState: TQueryMRTG = {
  keyword: '',
  size: 0,
  page: 0,
  status: '',
  mrtgStatus: '',
  reqMRTG: true
};

const queryMRTGSlice = createSlice({
  name: "queryMRTG",
  initialState,
  reducers: {
    setQueryMRTG: (state, { payload }: PayloadAction<TQueryMRTG>) => {
      Object.assign(state, payload);
    },
    setClearQueryMRTG: () => initialState,
  },
});

export const { setQueryMRTG, setClearQueryMRTG } =
  queryMRTGSlice.actions;

export const selectQueryMRTG = (state: RootState) => state.rootReducer.queryMRTG;

export default queryMRTGSlice;
