import { AxiosRequestConfig } from "axios";
import { getStorageToken } from "@helpers/storage";

import type { paramsList, IServiceDetail, IMosPayload } from "@services/interface";

export const BASE_URL: string = process.env.NEXT_PUBLIC_BASE_URL as string;
export const MOCK_URL: string = process.env.NEXT_PUBLIC_BASE_URL_MOCK as string;

const HomeServices = {
  getDashboardOverview: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/dashboard-overview`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  getProjectList: (params: paramsList, language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/dashboard/projects`,
    method: "GET",
    params,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id",
    },
  }),
  getMosReviewList: (params: paramsList, language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/mos/reviews`,
    method: "GET",
    params,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id",
    },
  }),
  getProductMenu: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/product-menu?level=0`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
    },
  }),
  getProductCustomer: (language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/product-customer?level=0`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
      lang: language,
    },
  }),
  getDashboardSummary: (language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/dashboard-summary`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id",
    },
  }),
  getTopServices: (language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/top-services`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id",
    },
  }),
  getServicesList: (params: paramsList): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/dashboard/services`,
    method: "GET",
    params,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  getServiceDetail: (id: string, params: IServiceDetail, language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/dashboard/services/${id}`,
    method: "GET",
    params,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id",
    },
  }),
  getServiceStatus: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/service-status`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  submitMosReview: (data: Partial<IMosPayload>, language:string): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/mos`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language ?? "id"
    },
  }),
};

export default HomeServices;
