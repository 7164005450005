import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";
import { AlertColor } from "@mui/material/Alert";

interface ISnackbar {
  isOpen?: boolean;
  message: string;
  type?: AlertColor;
}

export const initialState: ISnackbar = {
  isOpen: false,
  message: "",
  type: "success",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<ISnackbar>) => {
      const { message, type } = action.payload;
      state.isOpen = true;
      state.message = message;
      state.type = type;
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const selectSnackbar = (state: RootState) => state.rootReducer.snackbar;

export default snackbarSlice.reducer;
