import { AxiosRequestConfig } from "axios";

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

export const InsightServices = {
  getCategories: (): AxiosRequestConfig => ({
    url: `${baseUrl}/mytens-explore/v2/customer-news-categories`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
    },
  }),
  getInsight: (params: {
    keyword?: string;
    category?: string;
    page?: number;
  }, language?: string): AxiosRequestConfig => ({
    url: `${baseUrl}/mytens-explore/v2/customer-news`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
      lang: `${language}`
    },
    params,
  }),
  getInsightDetail: (id: string, language?: string): AxiosRequestConfig => ({
    url: `${baseUrl}/mytens-explore/v2/customer-news-detail/${id}`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
      url: process.env.NEXT_PUBLIC_HOSTNAME || "",
      lang: `${language}`
    },
  }),
};

export default InsightServices;
