import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";

export type WarningMaxWidth = "xl" | "lg" | "md" | "sm";

export type IPopupForm = {
  open?: boolean;
  image?: React.ReactNode;
  messages?: any[];
  type?: "SET_ROOT_WARNING";
  maxWidth?: WarningMaxWidth;
  applyButton?: any[];
  loader?: string;
};

const initialState: IPopupForm = {
  open: false,
  messages: [],
  type: "SET_ROOT_WARNING",
  maxWidth: "sm",
  applyButton: [],
};

const popupFormSlice = createSlice({
  name: "PopupForm",
  initialState,
  reducers: {
    setPopupForm: (state, { payload }: PayloadAction<IPopupForm>) => {
      Object.assign(state, payload);
      state.open = true;
    },
    setClosePopupForm: (state) => {
      state.open = false;
    },
    setClear: () => initialState,
  },
});

export const { setPopupForm, setClosePopupForm, setClear } = popupFormSlice.actions;

export const selectPopupForm = (state: RootState) => state.rootReducer.PopupForm;

export default popupFormSlice;
