const color: any = {
  primary: {
    dark: "#900E0E",
    main: "#C72037",
    mid: "#F55151",
    light: "#FAB7B7",
    lighter: "#F5E4E7",
    soft: "#FAF0F0",
  },
  general: {
    dark: "#111E24",
    main: "#2E434D",
    mid: "#78858B",
    light: "#B3C3CA",
    soft: "#E4E7E9",
  },
  inactive: {
    primary: "#EDF0F2",
    secondary: "#808C92",
    tertiary: "#8D98AA"
  },
  background: {
    default: "#EDF0F2",
    border: "#CAD2E2",
    primary: "#F5F7FA"
  },
  black: "#000000",
  white: "#FFFFFF",
  blue: {
    main: "#3071D9",
    secondary: "#293780",
    mid: "#476AC2",
    soft: "#CFE0FC",
  },
  yellow: {
    main: "#FAB005",
    light: "#FFC400",
    soft: "#FFF3BF",
    mid: "#FBF5E1"
  },
  purple: {
    main: "#9657D6",
    soft: "#EBDBFA",
  },
  orange: {
    main: "#FD7E14",
    soft: "#FFE8CC",
  },
  green: {
    main: "#3BA064",
    soft: "#ABEDC5",
    mid: "#BADCC6",
  },
  grey: {
    main: "#9E9E9E",
    light: "#DEE3ED",
    mid: "#F5F7FA",
    soft: "#F5F5F5",
    line: "#F1F3F7",
    bg: "#EAECF1",
    pure: "#C0C0C0"
  },
  red:{
    main: "#D42027",
  },
  placeholder: "#ADADAD",
  fault: "#FF483B",
  succeed: "#25974C",
  warn: "#FFAD00",
  gradient: {
    success: "linear-gradient(135deg, #1F8B41 0%, #65C285 100%)",
    failed: "linear-gradient(87.74deg, #E63958 -0.44%, #E21D0F -0.43%, #FF5F54 96.2%)",
    info: "linear-gradient(89.45deg, #424349 31.76%, #5B5D66 96.06%)",
    warning: "linear-gradient(143.93deg, #F78217 0%, #FFAF65 100%)",
    progress: "linear-gradient(135deg, #224397 0%, #476AC2 100%)",
  }
};

export const colorMapper = (colorStr: string) => {
  const colorStrSplit = colorStr?.split("-") || [];

  if (colorStrSplit.length === 2) {
    return color[colorStrSplit[0]][colorStrSplit[1]] || "inherit";
  } else if (colorStrSplit.length === 1) {
    return color[colorStrSplit[0]] || "inherit";
  } else {
    return "inherit";
  }
};

export type IColor =
  | "inherit"
  | "primary-dark"
  | "primary-main"
  | "primary-mid"
  | "primary-light"
  | "primary-soft"
  | "general-dark"
  | "general-main"
  | "general-mid"
  | "general-light"
  | "general-soft"
  | "inactive-main"
  | "inactive-secondary"
  | "black"
  | "white"
  | "blue-main"
  | "blue-secondary"
  | "blue-mid"
  | "blue-soft"
  | "yellow-main"
  | "yellow-soft"
  | "purple-main"
  | "purple-soft"
  | "orange-main"
  | "orange-soft"
  | "green-main"
  | "green-soft"
  | "grey-main"
  | "grey-light"
  | "grey-soft"
  | "grey-mid"
  | "fault"
  | "succeed"
  | "warn"
  | "placeholder"
  | "gradient-success"
  | "gradient-failed"
  | "gradient-info"
  | "gradient-warning"
  | "gradient-progress";

export default color;
