/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { NextPage } from "next";
import NextApp, { AppContext, AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import mediaQuery from "css-mediaquery";
import parser from "ua-parser-js";
import { CacheProvider, EmotionCache } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SEO from "@atoms/SEO";
import ProgressBar from "@badrap/bar-of-progress";
import AccountProvider from "@contexts/AccountProvider";
import LanguageProvider from "@contexts/LanguageProvider";
import color from "@definitions/mui/color";
import createEmotionCache from "@definitions/mui/createEmotionCache";
import theme from "@definitions/mui/theme";
import { gtmVirtualPageView } from "@helpers/analytics";
import firebaseMessaging from "@helpers/firebase/messaging";
import store from "@redux/store";
import "@styles/global.css";
import "swiper/swiper.scss";

const FormPopup = dynamic(() => import("@atoms/FormDialog"));
const PopupAlert = dynamic(() => import("@atoms/PopupAlert"));
const Warning = dynamic(() => import("@ds/components/Warning"));
const SnackbarCustom = dynamic(() => import("@ds/components/Snackbar"));
const VerificationDialog = dynamic(() => import("@atoms/VerificationDialog"));

const clientSideEmotionCache = createEmotionCache();

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  menuName?: string;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
  isMobile: boolean;
  pageProps: any;
}

const progress = new ProgressBar({
  size: 1.5,
  color: color.primary.main,
  className: "bar-of-progress",
});

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    ga: any;
  }
}

const mobileSsrMatchMedia = (query: string) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: "0px",
  }),
});
const desktopSsrMatchMedia = (query: string) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: "1024px",
  }),
});

const mobileMuiTheme = createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiUseMediaQuery: { defaultProps: { ssrMatchMedia: mobileSsrMatchMedia } },
  },
});
const desktopMuiTheme = createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiUseMediaQuery: { defaultProps: { ssrMatchMedia: desktopSsrMatchMedia } },
  },
});

const MyApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  isMobile,
}: MyAppProps) => {
  const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);
  const router = useRouter();

  const [isFCMToken, setIsFCMToken] = useState(false);
  if (isFCMToken) {
    firebaseMessaging.onMessage();
  }

  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GTM_ID}` });
    firebaseMessaging.init();
    const setToken = () => {
      const token = firebaseMessaging.getFCMToken();
      if (token) {
        setIsFCMToken(true);
      }
    };
    setToken();
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", progress.start);
    router.events.on("routeChangeComplete", () => {
      gtmVirtualPageView(
        {
          pageTitle: pageProps.title,
          page: document.location.pathname + document.location.search,
        },
        "virtualPageView",
      );
      progress.finish();
      return;
    });

    gtmVirtualPageView(
      {
        pageTitle: pageProps.title,
        page: document.location.pathname + document.location.search,
        originalLocation:
          // eslint-disable-next-line max-len
          `${document.location.protocol}/${document.location.hostname + document.location.pathname + document.location.search
          }`,
      },
      "virtualPageView",
    );

    return () => {
      router.events.off("routeChangeStart", progress.start);
      router.events.off("routeChangeComplete", progress.finish);
    };
  }, [router.events]);

  return (
    <CacheProvider value={emotionCache}>
      <SEO
        title={pageProps.title}
        description={pageProps.description}
        imageUrl={pageProps.imageUrl}
        canonical={pageProps.canonical}
      />
      <Provider store={store}>
        <ThemeProvider theme={isMobile ? mobileMuiTheme : desktopMuiTheme}>
          <LanguageProvider>
            <AccountProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <FormPopup />
              <PopupAlert />
              <Warning />
              <SnackbarCustom />
              <VerificationDialog />
              {getLayout(<Component {...pageProps} />)}
            </AccountProvider>
          </LanguageProvider>
        </ThemeProvider>
      </Provider>
    </CacheProvider>
  );
};

MyApp.getInitialProps = async (context: AppContext) => {
  let isMobile = false;

  if (context.ctx.req) {
    isMobile = parser(context.ctx.req.headers["user-agent"]).device.type === "mobile";
  }

  return {
    ...NextApp.getInitialProps(context),
    isMobile
  };
};

export default appWithTranslation(MyApp);
