/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react";
import { initialData } from "@containers/Profile/constant";
import { PROFILE_API } from "@libraries/repositories/profile/profile.hooks";
import getAccessTokenCookie, { clearCookies } from "@utils/cookie";
import { SERVICE_STATUS } from "@libraries/api/services-status/services-status.repos";
import ROUTES from "@config/routes";
import { useRouter } from "next/router";
import { logger } from "@helpers/logger";
import { resetList } from "@redux/actions";
import { getStorageLogin, resetAuth } from "@helpers/storage";
import { useAppDispatch } from "@redux/store";
import { IBillingAccount } from "@utils/storage-interface";

const AccountContext = createContext({
  account: initialData,
  clearAccountData: () => {},
  getAccountData: () => {},
  checkingSubscribe: () => {},
  logout: () => {},
  loading: false,
  isAuth: false,
  setIsAuth: (_: boolean) => {},
});

const AccountProvider: React.FC = ({ children }) => {
  const [account, setAccount] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const clearAccountData = () => {
    setAccount(initialData);
  };

  const getAccountData = async () => {
    setLoading(true);
    try {
      const resp = await PROFILE_API.getProfile();
      const temp = resp.data;
      const data = {
        name: temp.name || temp.fullName || "",
        email: temp.email || "",
        dialCode: temp.dialCode || "",
        phoneNumber: temp.phoneNumber || "",
        businessName: temp.businessName || "",
        industry: temp.industry || "",
        country: temp.country || "",
        city: temp.city || "",
        billingAccount: getStorageLogin()?.billingAccount || ([] as IBillingAccount[]),
      };
      setAccount(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const checkingSubscribe = async () => {
    try {
      const { data } = await SERVICE_STATUS.GET_SERVICE_STATUS();
      if (data.isAvailable) {
        // router.push(ROUTES.DASHBOARD());
        window.location.href = `/${router.locale === "en" ? "en/" : ""}dashboard`; // required to analytic requirement to sending user id
      } else {
        router.push(ROUTES.HOME());
      }
    } catch (err) {
      return logger.error(err);
    }
  };

  const logout = () => {
    clearCookies();
    dispatch(resetList());
    resetAuth();
    setAccount(initialData);
    setIsAuth(false);

    router.push(ROUTES.HOME());
  };

  useEffect(() => {
    setIsAuth(!!getAccessTokenCookie());
  }, []);

  useEffect(() => {
    if (isAuth) {
      getAccountData();
    } else {
      clearAccountData();
    }
  }, [isAuth]);

  return (
    <AccountContext.Provider
      value={{
        account,
        clearAccountData,
        getAccountData,
        loading,
        isAuth,
        checkingSubscribe,
        logout,
        setIsAuth,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;

export const useAccountContext = () => {
  return useContext(AccountContext);
};
