import { AxiosRequestConfig } from "axios";

export const BASE_URL: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const ArticlesServices = {
  getArticleCustomer: (language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/article-customer`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
      lang: language,
    },
  }),
  getArticleDetail: (id: string, language: string): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/article-customer/${id}`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
      lang: language,
    },
  }),
};

export default ArticlesServices;
