import type { CookieSerializeOptions } from "cookie";

export const COOKIE_CONFIG: CookieSerializeOptions = {
  path: "/",
  domain: ".mytens.co.id",
  // httpOnly: true,
  maxAge: 1000 * 60 * 60 * 24 * 15,
  sameSite: "lax",
};

/**
 * cookie names
 */
export const LOCALE_COOKIE = "_lang";
export const ACCESS_TOKEN_COOKIE = "_access_token";
export const GUEST_TOKEN_COOKIE = "_guest_token";
export const REFRESH_TOKEN_COOKIE = "_refresh_token";
export const USER_ID_COOKIE = "_user-id";
