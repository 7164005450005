import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";

// api
import { homeApi } from "@repositories/home/home.redux";
import { notificationApi } from "@repositories/notification/notification.redux";
import { myProjectServicesApi } from "@repositories/my-project-services/my-project-services.redux";
import { privilegeApi } from "@repositories/privilege/privilege.redux";
import { articlesApi } from "@repositories/articles/articles.redux";
import { insightApi } from "@repositories/insight/insight.redux";

import rootReducer from "./reducers";

export const store = configureStore({
  reducer: {
    rootReducer,
    // api reducer
    [homeApi.reducerPath]: homeApi.reducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [myProjectServicesApi.reducerPath]: myProjectServicesApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [privilegeApi.reducerPath]: privilegeApi.reducer,
    [insightApi.reducerPath]: insightApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      homeApi.middleware,
      myProjectServicesApi.middleware,
      notificationApi.middleware,
      privilegeApi.middleware,
      articlesApi.middleware,
      insightApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
