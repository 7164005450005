/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import HomeServices from "@services/home";
import { paramsList } from "@services/interface";

export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDashboardOverview: builder.query({
      query: HomeServices.getDashboardOverview,
    }),
    getProjectList: builder.query<any, { paramsList: paramsList; language: string }>({
      query: ({ paramsList, language }) => HomeServices.getProjectList(paramsList, language),
    }),
    getProductMenu: builder.query({
      query: () => HomeServices.getProductMenu(),
    }),
    getProductCustomer: builder.query<any, { language: string }>({
      query:({ language }) => HomeServices.getProductCustomer(language),
    }),
  }),
});

export const {
  useGetDashboardOverviewQuery,
  useGetProjectListQuery,
  useGetProductMenuQuery,
  useGetProductCustomerQuery,
} = homeApi;
