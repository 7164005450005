import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import MyProjectServices from "@services/my-project-services";
import { paramsList } from "@services/interface";

export const myProjectServicesApi = createApi({
  reducerPath: "myProjectServicesApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProjectInfo: builder.
      query<any, { projectId: string | string[] | undefined; language: string}>({
        query: ({ projectId, language }) =>
          MyProjectServices.getProjectInfo(projectId, language),
      }),
    getDeliverableList: builder.
      query<any, { projectId: string; paramsList: paramsList, language:string }>({
        query: ({ projectId, paramsList, language }) =>
          MyProjectServices.getDeliverableList(projectId, paramsList, language),
      }),
    getSCurve: builder.query<any, { projectId: string | string[] | undefined; language: string }>({
      query: ({ projectId, language}) => MyProjectServices.getSCurve(projectId, language),
    }),
  }),
});

export const {
  useGetProjectInfoQuery,
  useGetDeliverableListQuery,
  useGetSCurveQuery
} = myProjectServicesApi;
