import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { refresherToken } from "./network";
import { getStorageToken } from "./storage";
import { openSnackbar } from "@redux/actions";
import store from "@redux/store";
import getAccessTokenCookie, { getLocaleCookie } from "@utils/cookie";

const axiosBaseQuery = (): BaseQueryFn<AxiosRequestConfig, unknown> => async (config) => {
  try {
    const authToken = (config?.headers || {}).Authorization;
    const result = await axios({
      ...config,
      headers: {
        ...config.headers,
        Authorization: authToken?.includes("Basic")
          ? authToken
          : `Bearer ${getAccessTokenCookie()}`,
        lang: config.headers?.lang ?? getLocaleCookie(),
      },
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    // unauthorized
    if (err.response?.status === 401) {
      await refresherToken();
      const newToken = getStorageToken().accessToken;

      // refetch
      if (newToken) {
        try {
          config.timeout = 30000;

          const reRequest = await axios({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${newToken}`,
            },
          });
          return { data: reRequest.data };
        } catch (axiosError) {
          const err = axiosError as AxiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      }
    }

    if (!err.response || err.response?.status >= 500) {
      let errorMessage = "Something went wrong.";
      if (!navigator.onLine) {
        errorMessage = "No internet connection.";
      }
      store.dispatch(openSnackbar({ message: errorMessage || "", type: "error" }));
    }

    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export default axiosBaseQuery;
