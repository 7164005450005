const ROUTES = {
  HOME() {
    return `/`;
  },
  ABOUT() {
    return `/about`;
  },
  BUSINESS_SOLUTION(id: string) {
    return `/business-solution/${id}`;
  },
  BILLING() {
    return `/billing`;
  },
  LOGIN() {
    return `/login`;
  },
  FORGOT_PASSWORD() {
    return `/lupa-password/confirm-username`;
  },
  DASHBOARD() {
    return `/dashboard`;
  },
  PROJECT() {
    return `/projects`;
  },
  PROJECT_DETAIL(id: string) {
    return `/projects/${id}`;
  },
  FAQ() {
    return `/faq`;
  },
  HELP() {
    return `/bantuan`;
  },
  PERFORMANCE() {
    return `/performance`;
  },
  PROJECT_AND_SERVICE() {
    return `/projects`;
  },
  PERFORMANCE_DETAIL(id?: string) {
    return `/performance/${id}`;
  },
  PROFILE() {
    return `/profile`;
  },
  PRODUCT() {
    return `/mobile/produk`;
  },
  NOTIFICATION() {
    return `/notification`;
  },
  ARTICLE_PREVIEW() {
    return `/articles/preview`;
  },
  ARTICLE(id: string) {
    return `/articles/${id}`;
  },
  DETAIL_PRODUCT_SERVICES(
    productLevel?: number | string | string[],
    productSlug?: string | string[],
  ) {
    return `/product-services/${productLevel}/${productSlug}`;
  },
  DETAIL_INTEREST_FORM(productLevel?: number | string | string[], productSlug?: string | string[]) {
    if (!productSlug) {
      return `/interested-form`;
    }
    return `/product-services/${productLevel}/${productSlug}/interested-form`;
  },
  ALL_FAULT_TICKETS() {
    return `/tickets`;
  },
  CREATE_FAULT_TICKET() {
    return `/new-ticket`;
  },
  BILL_AND_PAYMENTS() {
    return `/bill-and-payments`;
  },
  BILL_AND_PAYMENTS_DETAILS(id?: string) {
    return `/bill-and-payments/${id}`;
  },
  MY_CLAIMS() {
    return `/my-claims`;
  },
};

export default ROUTES;
