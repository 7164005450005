import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";
import {
  initialState,
  IPayloadMeta,
  IPayloadOrderBy,
  IPayloadOrderDirection,
  IPayloadTab,
  IPayloadSearch,
  IPayloadFilter,
} from "./constant";

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    setMeta: (state, action: PayloadAction<IPayloadMeta>) => {
      const { key, meta } = action.payload;
      state[key].meta = meta;
    },
    setOrderBy: (state, action: PayloadAction<IPayloadOrderBy>) => {
      const { key, orderBy } = action.payload;
      state[key].orderBy = orderBy;
    },
    setOrderDirection: (state, action: PayloadAction<IPayloadOrderDirection>) => {
      const { key, orderDirection } = action.payload;
      state[key].orderDirection = orderDirection;
    },
    setTab: (state, action: PayloadAction<IPayloadTab>) => {
      const { key, tab } = action.payload;
      state[key].tab = tab;
    },
    setSearchList: (state, action: PayloadAction<IPayloadSearch>) => {
      const { key, search } = action.payload;
      state[key].search = search;
    },
    setFilter: (state, action: PayloadAction<IPayloadFilter>) => {
      const { key, filter } = action.payload;
      state[key].filter = filter;
    },
    resetList: () => {
      return initialState;
    },
  },
});

export const {
  setMeta,
  setOrderBy,
  setOrderDirection,
  setTab,
  setSearchList,
  setFilter,
  resetList,
} = listSlice.actions;

export const selectList = (state: RootState) => state.rootReducer.list;

export default listSlice.reducer;
