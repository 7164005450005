import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";

interface ICounter {
  count: number;
}

const initialState: ICounter = {
  count: 20,
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increase: (state) => {
      state.count++;
    },
    decrease: (state) => {
      state.count--;
    },
  },
});

export const { increase, decrease } = counterSlice.actions;

export const selectCounter = (state: RootState) => state.rootReducer.counter;

export default counterSlice.reducer;
