/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@helpers/axiosBaseQuery";
import ArticlesServices from "@services/articles";

export const articlesApi = createApi({
  reducerPath: "articlesApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getArticleCustomer: builder.query<any, { language: string }>({
      query: ({ language }) => ArticlesServices.getArticleCustomer(language),
    }),
  }),
});

export const { useGetArticleCustomerQuery } = articlesApi;
