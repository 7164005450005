import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@redux/reducers";

interface ISearch {
  search?: string;
}

const initialState: ISearch = {
  search: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { setSearch } = searchSlice.actions;

export const selectSearch = (state: RootState) => state.rootReducer.search.search;

export default searchSlice.reducer;
