import { RootState } from "@redux/reducers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TWarning } from "./types";

const initialState: TWarning = {
  open: false,
  messages: [],
  additionalContent: null,
  type: "SET_ROOT_WARNING",
  maxWidth: "sm",
  applyButton: [],
  contentType: "",
  dataContent: {
    issueCategory: "",
    issueDescription: "",
    pic1Name: "",
    pic1Number: "",
    pic2Name: "",
    pic2Number: "",
    typeOfTicket: ""
  }
};

const warningSlice = createSlice({
  name: "warning",
  initialState,
  reducers: {
    setWarning: (state, { payload }: PayloadAction<TWarning>) => {
      Object.assign(state, payload);
      state.open = true;
    },
    setCloseWarning: (state) => {
      state.open = false;
    },
    setClear: () => initialState
  }
});

export const { setWarning, setCloseWarning, setClear } = warningSlice.actions;

export const selectWarning = (state: RootState) => state.rootReducer.warning;

export default warningSlice;
