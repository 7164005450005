import useMetaNoIndex from "@helpers/useRobotNoIndex";
import Head from "next/head";
import { useRouter } from "next/router";

interface ISeoProps {
  title: string;
  description?: string;
  imageUrl?: string;
  canonical?: string;
}

export const SEO: React.FC<ISeoProps> = ({
  title = "MyTEnS",
  description,
  imageUrl = "",
  canonical = "/",
}) => {
  const router = useRouter();
  const { locale } = router;
  const URL = router.asPath;
  const { noIndex } = useMetaNoIndex();

  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta name="description" content={description} />
      <meta property="og:title" content={`MyTEnS | ${title}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={canonical} />
      <meta name="twitter:title" content={`MyTEnS | ${title}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content={imageUrl} />
      <meta name="google-site-verification" content="nXhYOWnqa-U3a1IKl5Tph_bpkUbvAmx7oP0nwuCxbx8" />
      {noIndex && <meta name="robots" content="noindex" />}
      <link key="canonical" rel="canonical" href={canonical} />
      <link
        rel="alternate"
        hrefLang={locale}
        href={`${process.env.NEXT_PUBLIC_HOSTNAME}${locale === "en" ? "/en" + URL : URL}`}
      />
      <link
        rel="alternate"
        hrefLang={locale === "en" ? "id" : "en"}
        href={`${process.env.NEXT_PUBLIC_HOSTNAME}${locale === "en" ? URL : "/en" + URL}`}
      />
      <script
        key="webpage"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${title}",
          "description": "${description}",
          "publisher": {
            "@type": "Organization",
            "name": "Telkom DWS"
          }}`,
        }}
      />
    </Head>
  );
};

export default SEO;
