/* eslint-disable @typescript-eslint/ban-types */
import { getStorageToken } from "@helpers/storage";
import { AxiosRequestConfig } from "axios";

const BASE_URL: string = process.env.NEXT_PUBLIC_BASE_URL as string;

export const ServiceStatus = {
  getServiceStatus: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/services/customer/v1/service-status`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
};
