import { IMeta } from "@containers/interface";
import { LabelProps } from "@atoms/Filter/interface";

export type KeyType = "home" | "performance" | "fault" | "notification" | "billing" | "mos";

const isDev =
  ["https://dev.mytens.co.id", "https://staging.mytens.co.id"].includes(
    process.env.NEXT_PUBLIC_HOSTNAME || "",
  ) || false;

export interface IPayloadMeta {
  key: KeyType;
  meta: IMeta;
}

export interface IPayloadOrderBy {
  key: KeyType;
  orderBy: string;
}

export interface IPayloadOrderDirection {
  key: KeyType;
  orderDirection: string;
}

export interface IPayloadTab {
  key: KeyType;
  tab: string;
}

export interface IPayloadSearch {
  key: KeyType;
  search: string;
}

export interface IPayloadFilter {
  key: KeyType;
  filter: LabelProps[];
}

export interface IListSlice {
  meta: IMeta;
  orderBy: string;
  orderDirection: string;
  tab: string;
  search: string;
  filter: LabelProps[];
}

export const initialMeta = {
  page: 1,
  size: 10,
  totalData: 1,
  totalPage: 1,
};

export const initial: IListSlice = {
  meta: initialMeta,
  orderBy: "",
  orderDirection: "asc",
  tab: isDev ? "services" : "project",
  search: "",
  filter: [],
};

export const initialState = {
  home: {
    ...initial,
    tab: isDev ? "services" : "project",
  },
  billing: {
    ...initial,
    tab: "billing-account",
  },
  performance: initial,
  fault: {
    ...initial,
    tab: "open",
  },
  notification: initial,
  mos: initial,
};
