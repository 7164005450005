import { AxiosRequestConfig } from "axios";
import { getStorageToken } from "@helpers/storage";

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const PrivilegeServices = {
  getPrivileges: (): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v3/privilege`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
};

export default PrivilegeServices;
