interface IGTtmDataLayer {
  pageTitle: string;
  page: string;
  originalLocation?: string;
  pageLocation?: string;
}

interface IGTtmEventDataLayer {
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
}

interface IGtmUserIdDataLayer {
  userId: string;
}

export const gtmVirtualPageView = (rest: IGTtmDataLayer, event: string) => {
  window.dataLayer?.push({
    ...rest,
    event,
    eventAction: undefined,
    eventCategory: undefined,
    eventLabel: undefined,
  });
};

export const gtmCustomEvent = (rest: IGTtmEventDataLayer, event: string) => {
  window.dataLayer?.push({
    ...rest,
    event,
  });
};

export const gtmSendUserId = (rest: IGtmUserIdDataLayer) => {
  window.dataLayer.push({
    ...rest
  });
};

export const getClientId = () => {
  if (typeof window !== "undefined") {
    const tracker: string[] = [];
    window.ga?.getAll().map((item: any) => {
      if (tracker.indexOf(item.get("clientId")) === -1) tracker.push(item.get("clientId"));
    });
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!tracker.length) {
      return tracker[0];
    } else {
      return null;
    }
  }
};
