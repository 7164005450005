import { AxiosRequestConfig } from "axios";
import { IProfile } from "@containers/Profile/interface";
import { getStorageToken } from "@helpers/storage";
// import getAccessTokenCookie, { getLocaleCookie } from "@utils/cookie";

export const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const ProfileServices = {
  getProfile: (): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v3/customer-profile`,
    method: "GET",
    headers: {},
    //   // Authorization: `Bearer ${getStorageToken().accessToken}`,
    //   Authorization: `Bearer ${getAccessTokenCookie()}`,
    //   lang: getLocaleCookie(),
    // },
  }),
  putProfile: (data: Partial<IProfile>): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v3/customer-profile`,
    method: "PUT",
    data,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  getIndustry: (): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v1/general/industry`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: "en",
    },
  }),
  getCountry: (): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v1/general/country`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  getCity: (countryName: string): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v1/general/city`,
    method: "GET",
    params: {
      countryName,
    },
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
    },
  }),
  PutCustomerChangePassword: (
    data: {
      newPassword?: string;
      reEnterNewPassword?: string;
      otp: string;
      signatureCode: string;
    },
    language: string,
  ): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v3/customer-change-password`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${getStorageToken().accessToken}`,
      lang: language,
    },
  }),
};

export default ProfileServices;
