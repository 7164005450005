/* eslint-disable no-console */
import { appFirebase, key } from "./config";
import {
  getMessaging,
  onMessage as onMessageFirebase,
  getToken,
  isSupported,
  NotificationPayload,
  MessagePayload,
} from "firebase/messaging";
import { favicon } from "@config/images";

export const getFCMToken = () => {
  const token = localStorage.getItem("fcm_token");
  return token;
};

export const onMessage = async () => {
  if (!(await isSupported())) {
    return null;
  }

  const messaging = getMessaging();
  onMessageFirebase(messaging, (payload) => {
    recieveNotif(payload);
  });
};

export const recieveNotif = (payload: MessagePayload) => {
  const data: NotificationPayload = payload.notification ?? {};

  const body = {
    ...data,
    icon: favicon,
  };

  new Notification(data.title ?? "", body);
};

export const init = async () => {
  try {
    if (!(await isSupported())) {
      return null;
    }

    if (getFCMToken() !== null) {
      return false;
    }

    const messaging = getMessaging(appFirebase);
    await Notification.requestPermission();
    getToken(messaging, { vapidKey: key })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to server and update the UI if necessary
          // save the token in database
          localStorage.setItem("fcm_token", currentToken);
        } else {
          console.log(
            "NOTIFICATION, No registration token available. Request permission to generate one.",
          );
        }
      })
      .catch((err) => {
        console.log("NOTIFICATION error occurred while retrieving token . ");
        console.log(err);
      });
  } catch (error) {
    console.error(error);
  }
};

export default {
  onMessage,
  recieveNotif,
  init,
  getFCMToken,
};
